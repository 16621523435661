import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Hadi Mirza | Full Stack Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Hadi.',
  subtitle:
    'I am a software developer from Toronto with experience in building fluid, full-stack web applications.',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profilepic.jpeg',
  paragraphThree: `I've always wanted to understand how applications on machines just...worked. Today I'm developing them end-to-end for startups and large companies.`,
  paragraphTwo: `That was the day I knew I wanted to be a developer. I was just a curious young adult but that title as a 'developer' was enough to ignite a passion in me to actually become one.`,
  postUrl: `https://appleinsider.com/articles/08/07/25/copypaste_references_spotted_in_existing_iphone_frameworks`,
  quoteAuthor: `- AppleInsider`,
  quote: `One developer burning the midnight oil has stumbled upon references to copy and paste in the frameworks of Apple's first beta of iPhone Software 2.1, potentially offering new hope for one of the most requested and conspicuously absent features of the company's mobile software...`,
  paragraphOne: `About 13 years ago, I was snooping around in iPhone's early iOS beta firmware and found something interesting. At this time, iPhone didn't have copy and paste functionality (yeah, can you believe it?) and I found some files referencing it. I posted it on one of the early forums for Apple news and the next morning, to my surprise, the publication wrote a front-page piece on my discovery. The first sentence was...`,
  resume: 'Hadi-Mirza-CV.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'cmc.png',
    title: 'ComplimentMyClothes',
    info: `ComplimentMyClothes is a web application designed to support users in organizing and finding the perfect pairings for every article of clothing in their wardrobe. Each item is provided with a list of complimentary colours and items according to the current temperature, making getting dressed everyday easier!`,
    info2: '',
    url: 'https://complimentmyclothes.herokuapp.com/',
    repo: 'https://github.com/hadi-mirza/ComplimentMyClothes', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'speedbench.png',
    title: 'SpeedBench',
    info: `SpeedBench is a speed test benchmarking tool made using fast.com-api, ipData API, Express, Mongoose, and MongoDB. This application helps users globally perform and save their speed tests and also compare tests to others on their same ISP.`,
    info2: '',
    url: 'https://speedbench.herokuapp.com/',
    repo: 'https://github.com/hadi-mirza/SpeedBench', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'cargoio.png',
    title: 'Cargo.io',
    info: `This app is designed to connect freight vehicle owners to everyday users for delivery of common items. Let's say you're browsing Kijiji and eventually find a great deal on an antique table but have no way to transport it to your location.`,
    info2: `Cargo.io provides you with a seamless process of finding an appropriate vehicle to pickup and deliver the product to your desired location at your specified time. Cargo.io eliminates the extra cost of delivery fees and tentative delivery times given by retail stores.`,
    url: 'https://cargoio.herokuapp.com/',
    repo: 'https://github.com/hadi-mirza/cargo-io', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'roulette.png',
    title: 'Roulette',
    info:
      'Roulette is a take on the classic casino game. Created with HTML5, CSS3 and JavaScript. Place your bets and test your luck!',
    info2: '',
    url: 'https://hadi-mirza.github.io/roulette/',
    repo: 'https://github.com/hadi-mirza/roulette', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'I would love to hear from you. Email me at hadimirza1@gmail.com or click below!',
  btn: '',
  email: 'hadimirza1@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/hadi-mirza',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/hvzy',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/hadimirza1/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
